import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

const FooterPortal = ({ children }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted
    ? createPortal(children,
      document.querySelector("#my105-footer-portal"))
    : null
}

export default FooterPortal