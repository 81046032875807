import React, { useState } from "react"
import Link from "next/link";
import styled from "styled-components";
import layout from "../../services/layout";
import SectionHeadingLink from "./SectionHeadingLink";
import { Button, Icon } from "semantic-ui-react";

const VehicleLinksContainer = styled.div`
  padding: 20px 50px 30px 50px;

  @media (max-width: ${layout.global.mobileMax}) {
    padding: 20px 10px 30px 10px;
  }

  a {
    color: white !important;
  }
`

const MakeLinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  &.is-collapsed {
    max-height: 300px;
    overflow: hidden;
    
    &:after {
      content: ' ';
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100px;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0) 0%, rgba(46, 46, 46, 0.5) 20%, rgba(46, 46, 46, 1) 60%);
      z-index: 2;
      pointer-events: none;
    }
  
    button { 
      z-index: 3;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      &.basic.inverted.button {
        border: 0 !important;
        box-shadow: none !important;
      }
    }

  }
`

const MakeLinkItem = styled.li`
  display: flex;
  position: relative;
  white-space: nowrap;

  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(90deg, rgba(46, 46, 46, 0) 0%, rgba(46, 46, 46, 0.5) 20%, rgba(46, 46, 46, 1) 100%);
    z-index: 2;
  }
`

const MakeLink = styled.span`
  display: inline-block;
  a {
    color: white !important;
    font-weight: bold;
    display: inline-block;
    text-align: right;
    min-width: 125px;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${layout.global.mobileMax}) {
      min-width: 100px;
      max-width: 100px;
    }
  }
  &:after {
    content: '>';
    margin: 0 10px;
    vertical-align: top;
  }
`

const ModelLinkList = styled.ul`
  list-style: none;
  padding: 0 50px 0 0;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ModelLinkItem = styled.li`
  display: inline;
  &:after {
    content: '|';
    margin: 0 10px;
  }
  &:last-child :after {
    content: none;
  }
`

const ModelLink = styled.span`
  a {
    color: #ddd !important;
  }
`

function VehicleLinks({ vehicleHierarchy }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return <VehicleLinksContainer>
    <SectionHeadingLink href='/cars'>
      Browse by car
    </SectionHeadingLink>
    <MakeLinkList className={isCollapsed ? 'is-collapsed' : ''}>
      {vehicleHierarchy.makes.map(make => <MakeLinkItem key={make.slug}>
        <MakeLink>
          <Link href={'/cars/' + make.slug + '?source=HOME_BROWSE_BY_VEHICLE'}>{`${make.name}`}</Link>
        </MakeLink>
        <ModelLinkList>
          {make.children.map(model => <ModelLinkItem key={model.slug}>
            <ModelLink>
              <Link href={'/cars/' + model.slug + '?source=HOME_BROWSE_BY_VEHICLE'}>{`${model.name}`}</Link>
            </ModelLink>
          </ModelLinkItem>)}
        </ModelLinkList>
      </MakeLinkItem>)}
      <MakeLinkItem>
        <MakeLink>
          <Link href={'/cars?source=HOME_BROWSE_BY_VEHICLE'}>Other Makes</Link>
        </MakeLink>
        <ModelLinkList>
          {vehicleHierarchy.models.map(model => <ModelLinkItem key={model.slug}>
            <ModelLink>
              <Link href={'/cars/' + model.slug + '?source=HOME_BROWSE_BY_VEHICLE'}>{`${model.name}`}</Link>
            </ModelLink>
          </ModelLinkItem>)}
        </ModelLinkList>
      </MakeLinkItem>
      {isCollapsed && <Button basic inverted onClick={() => setIsCollapsed(false)}><Icon name='angle down' /> Show All</Button>}
    </MakeLinkList>
  </VehicleLinksContainer>
}

export default VehicleLinks;