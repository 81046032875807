import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import layout from '../../services/layout';

const CategoryLinksContainer = styled.div`
  padding: 20px 50px 30px 50px;

  @media (max-width: ${layout.global.mobileMax}) {
    padding: 10px 10px 30px 10px;
  }
`;

const CategoryLinkList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  max-height: 200px;
  margin: 0;

  @media (max-width: ${layout.global.smallMax}) {
    max-height: 380px;
  }
`;

const CategoryLinkItem = styled.li`
  a {
    color: white !important;
  }
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 380px) {
    width: 140px;
  }
`;

function CategoryLinks({ categories }) {
  return (
    <CategoryLinksContainer>
      <CategoryLinkList>
        {categories.map((category) => (
          <CategoryLinkItem key={category.slug}>
            <Link
              href={
                '/for-sale/' + category.slug + '?source=HOME_BROWSE_BY_CATEGORY'
              }
            >
              {category.label || category.title}
            </Link>
          </CategoryLinkItem>
        ))}
      </CategoryLinkList>
    </CategoryLinksContainer>
  );
}

export default CategoryLinks;
