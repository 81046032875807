import React from 'react';
import styled from 'styled-components';
import layout from '../../services/layout';
import Link from 'next/link';
import Image from 'next/image';
import SectionHeadingLink from './SectionHeadingLink';

const CategoryTilesContainer = styled.div`
  padding: 0 0 40px 0;

  a {
    color: white !important;
  }
`

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
`

const Tile = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 40px;

  && {
    .header {
      margin-top: 0;
    }
    .sub.header {
      margin-top: 10px;
    }
  }
`

const TileImage = styled(Image)`
  display: block;
  margin: 0 auto;

  @media (max-width: ${layout.global.largeMax}) {
    width: 200px !important;
    height: 92px !important;
    min-width: initial !important;
    min-height: initial !important;
  }

  @media (max-width: ${layout.global.smallMax}) {
    width: 150px !important;
    height: 69px !important;
  }
`

const MobileDivider = styled.div`
  display: none;
  order: 1;

  @media (max-width: ${layout.global.mediumMax}) {
    display: block;
    flex-basis: 100%;
  }
`

const categories = [
  {
    title: 'Motorsport',
    url: '/search/motorsport',
    image: '/category-icons/motorsport-transparent-small.png'
  },
  {
    title: 'Performance',
    url: '/search/performance',
    image: '/category-icons/performance-transparent-small.png'
  },
  {
    title: 'Classic',
    url: '/search/car-bike-kart/classic-prestige',
    image: '/category-icons/classic-transparent-small.png'
  },
  {
    title: 'Transporters',
    url: '/search/trailer-transporter',
    image: '/category-icons/transporter-transparent-small.png'
  }
]

function CategoryTiles() {

  return (
    <CategoryTilesContainer>
      <SectionHeadingLink href='/search?source=HOME_BROWSE_BY_CATEGORY'>
        Browse by category
      </SectionHeadingLink>

      <Tiles>
        {
          categories.map((category, index) => {
            return <Tile key={category.title} style={{ order: index }}>
              <Link href={category.url + '?source=HOME_BROWSE_BY_CATEGORY'}>
                <TileImage src={category.image} alt={category.title} width='242' height='111' />
                <div>{category.title}</div>
              </Link>
            </Tile>
          })
        }
        <MobileDivider />
      </Tiles>
    </CategoryTilesContainer >
  )
}

export default CategoryTiles
