import React from 'react';
import styled from 'styled-components';
import layout from '../../services/layout';
import Link from 'next/link';
import AnalyticEventService from '../../services/AnalyticEventService';
import FooterPortal from '../furniture/FooterPortal';

const HeroContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: end;
  justify-content: space-evenly;
  gap: 20%;
  overflow: hidden;

  @media (max-width: ${layout.global.mobileMax}) {
    gap: 10px;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
  }

  &.motorsport-australia-partner {
    position: relative;

    @media (min-width: ${layout.global.largeMax}) {
      max-height: calc(80vh);
    }
  }
`;

const BackgroundImage = styled.img`
  filter: brightness(60%);
  object-fit: cover;
  object-position: center;
  position: absolute;
  height: 100%;
  width: 100%;

  &.mobile-only {
    display: none;
  }
  @media (max-width: ${layout.global.mobileMax}) {
    &.desktop-only {
      display: none;
    }
    &.mobile-only {
      display: block;
    }
  }
  @media (min-width: 1500px) {
    &.desktop-only {
      object-fit: contain;
    }
  }

  &.motorsport-australia-partner {
    object-fit: contain;
    position: relative;
    object-position: bottom;
  }
`;

const HeroActionContainer = styled(Link)`
  z-index: 1;
  cursor: pointer;
  &,
  &:hover,
  &:active {
    color: white;
  }

  margin-bottom: 30px;
  @media (max-width: ${layout.global.mobileMax}) {
    margin-bottom: 20px;
  }

  h2 {
    background: #2e2e2e;
    padding: 10px 30px;
    border-radius: 100px;
    text-align: center;
    line-height: 42px;
    box-shadow: 0 0 5px 2px rgba(218, 33, 40) !important;
  }
`;

function HeroAction({ title, url }) {
  return (
    <HeroActionContainer href={url}>
      <h2>{title}</h2>
    </HeroActionContainer>
  );
}

const HeroFullWidthClickAreaContainer = styled.a`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    top: 0;
  }
  &:last-child {
    bottom: 0;
  }

  &.motorsport-australia-partner {
    top: 0;
  }
`;

function HeroFullWidthClickArea({ takeOver, position, children }) {
  const isExternal = !takeOver.ctaUrl.startsWith('https://www.my105.com/');
  return (
    <HeroFullWidthClickAreaContainer
      href={takeOver.ctaUrl}
      target={isExternal && '_blank'}
      rel={isExternal && 'noopener noreferrer'}
      className={takeOver.className}
      onClick={() => {
        AnalyticEventService.createAnalyticEvent({
          eventType: 'HOMEPAGE_TAKEOVER',
          metadata: {
            action: 'click',
            position: position,
            advertiser: takeOver.advertiser,
          },
        });
      }}
    >
      {takeOver.ctaText && position === 'bottom' && (
        <div
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          {takeOver.ctaText}
        </div>
      )}
      {children}
    </HeroFullWidthClickAreaContainer>
  );
}

const TakeOverImageContainer = styled.div`
  margin-top: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  img {
    max-width: 500px;
    @media (max-width: ${layout.global.mobileMax}) {
      max-width: 100%;
    }
  }
`;

function RoadOrRaceHero({ takeOver }) {
  return (
    <HeroContainer className={takeOver?.className}>
      {takeOver ? (
        <>
          {takeOver.className ? (
            <>
              <BackgroundImage
                src={takeOver.desktop}
                className={takeOver.className + ' desktop-only'}
              />
              <BackgroundImage
                src={takeOver.mobile}
                className={takeOver.className + ' mobile-only'}
              />
              <HeroFullWidthClickArea
                takeOver={takeOver}
                position="bottom"
              ></HeroFullWidthClickArea>
            </>
          ) : (
            <>
              <HeroFullWidthClickArea takeOver={takeOver} position="top" />
              <BackgroundImage
                src={takeOver.desktop}
                className="desktop-only"
              />
              <BackgroundImage
                src={takeOver.mobile}
                fill
                className="mobile-only"
              />
              <HeroFullWidthClickArea takeOver={takeOver} position="bottom">
                <TakeOverImageContainer>
                  <img
                    src="/homepage-bg/machineryhouse-tag-line.png"
                    alt="One Stop Machinery Shop"
                  />
                  <img
                    src="/homepage-bg/machineryhouse-logo.png"
                    alt="Hare & Forbes Machinery House"
                  />
                </TakeOverImageContainer>
              </HeroFullWidthClickArea>
            </>
          )}
        </>
      ) : (
        <>
          <BackgroundImage
            src="/homepage-bg/road-or-race.jpg"
            fill
            alt="Road or Race"
            priority
          />
          <HeroAction
            title="Road"
            url="/search/performance?source=HOME_RACE_OR_ROAD"
          />
          <HeroAction
            title="Race"
            url="/search/motorsport?source=HOME_RACE_OR_ROAD"
          />
        </>
      )}
      <FooterPortal>
        <small>
          Homepage image used with permission from WhichCar.com. Photographer:
          Christian Brunelli.
        </small>
      </FooterPortal>
    </HeroContainer>
  );
}

export default RoadOrRaceHero;
