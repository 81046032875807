
import axios from 'axios';
import config from './config';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

const withErrorHandling = async (func) => {
  try {
    const response = await func();
    return response.data;
  }
  catch (ex) {
    if (ex.response && ex.response.status === 404) {
      return null;
    }
    throw ex;
  }
}

export default {
  search: async queryUrl => {
    return await withErrorHandling(() => client.get('/inventory/search/' + queryUrl));
  },
  searchByQuery: async query => {
    const response = await client.post('/inventory/search/', query);
    return response.data;
  },
  homepageRefinements: async query => {
    const response = await client.post('/inventory/homepage', query);
    return response.data;
  }
}