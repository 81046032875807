import axios from 'axios';
import config from './config';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

let summaryCache;

const AdSurveyResponseService = {
  getAdSurveyResponseSummary: async () => {
    if (summaryCache) {
      return summaryCache;
    }

    let response = await client.get('/ad-survey-response/summary');

    summaryCache = response.data;

    return response.data;
  },
  getAdSurveyResponses: async (from) => {
    let response = await client.get('/ad-survey-response/' + from);

    return response.data;
  }
}

export default AdSurveyResponseService;