import React from 'react';
import Rating from 'react-rating';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const RatingStarsContainer = styled.div`
  white-space: nowrap;
  i.icon {
    color: #ed1f24;
    font-size: 16px !important;
    margin: 0 2px !important;
  }
`;

export default function RatingStars({ rating }) {
  return (
    <RatingStarsContainer>
      <Rating
        initialRating={rating}
        emptySymbol={<Icon name="star outline" />}
        fullSymbol={<Icon name="star" />}
        readonly
      />
    </RatingStarsContainer>
  );
}
